@import "src/styles/partials/variables";
@import "src/styles/partials/mixins";

.event-green-oval {
  @include oval-background($color-green-1, 18px, 18px, 24px);
}

.event-orange-oval {
  @include oval-background($color-accent-1, 18px, 20px, 24px);
}

.event-blue-oval {
  @include oval-background($color-primary, 18px, 20px, 24px);
}

.event-warn-oval {
  @include oval-background($color-warn-solid, 18px, 20px, 24px);
}

.event-bg {
  background-color: unset !important;
}

.event-green {
  @extend .event-bg;
  color: $color-green-1 !important;
}

.event-accent {
  @extend .event-bg;
  color: $color-accent-1 !important;
}

.event-warn {
  @extend .event-bg;
  color: $color-warn-solid !important;
}

.event-orange {
  @extend .event-bg;
  color: $color-accent-1 !important;
}

.event-blue {
  @extend .event-bg;
  color: $color-primary !important;
}