@import "./variables";

.app-table {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.app-table-head {
  @extend .mat-subtitle-2;
  background: $new-a4-portal-neutral-0;
  min-height: 40px;
  height: fit-content;
  padding: 8px 0 8px 16px;
  font-weight: 700 !important;
}

.app-table-row {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #FFFFFF;
  filter: drop-shadow(0px 2px 4px rgba(25, 57, 89, 0.22));
  margin-bottom: 8px;
}

.app-table-row-content {
  place-content: center flex-start;
  align-items: center;
  min-height: 62px;
  padding: 16px;
}

.app-table-cell {
  word-break: break-word;
  padding-right: 10px;
}


