@import "variables";
@import "../typography";

p-table {
  border-radius: 12px !important
}

p-calendar,
p-dropdown,
.p-link {
  @extend .mat-body;

  &::placeholder {
    color: $color-grey-4;
  }

  &.ng-invalid.ng-touched,
  &.ng-invalid.ng-dirty {
    border: 1px solid $color-a4-portal-orange;
  }

}

.p-sortable-column-icon {
  color: $color-neutral-blue-0 !important;
}

.p-column-filter-menu-button:hover {
  background: $color-green-2;
}

.p-button-header {
  width: 190px !important;
}

.p-button {
  color: $color-neutral-blue-0;
  width: 140px;
  background: none;
  border: 1px solid $color-neutral-blue-0;

  // &:enabled:focus {
  //   box-shadow: 0 0 0 0.1rem $color-neutral-blue-0;
  // }

  // &:enabled:hover {
  //   background: $color-neutral-blue-0;
  //   border: 1px solid $color-neutral-blue-0;
  // }

  // &:enabled:active {
  //   background: $color-neutral-blue-0;
  //   border: 1px solid $color-neutral-blue-0;
  // }

  // &:enabled:focus {
  //   outline: 0 !important;
  //   outline-offset: 0px;
  //   box-shadow: none;
  // }

  &:enabled:focus-visible {
    outline: 0 !important;
    outline-offset: 0px;
    box-shadow: none;
  }

}

// .p-button.p-button-text {
//   color: $color-neutral-blue-0;

//   &:enabled:hover {
//     color: $color-neutral-blue-0;
//     border: 1px solid $color-neutral-blue-0;
//   }

//   &:enabled:active {
//     background: $new-a4-portal-neutral-3;
//     color: $color-neutral-blue-0;
//   }

// }

// .p-button.p-button-outlined {
//   color: $color-neutral-blue-0;

//   &:enabled:hover {
//     color: $color-neutral-blue-0;
//     border: 1px solid $color-neutral-blue-0;
//   }

//   &:enabled:active {
//     background: $new-a4-portal-neutral-3;
//     color: $color-neutral-blue-0;
//   }

// }

.p-inputtext {

  &:enabled:hover {
    border: 1px solid $color-neutral-blue-0;
  }

  &:enabled:focus {
    border: 1px solid $color-neutral-blue-0;
    box-shadow: 0 0 0 0.1rem $color-neutral-blue-0;
  }
}

.p-component,
.p-inputtext {
  @extend .mat-body;
  margin-bottom: 0px !important
}

.p-paginator-rpp-options {
  width: 90px !important;
  background: $new-a4-portal-neutral-3;
}


.p-dropdown {
  // width: 400px !important;
  // background: $new-a4-portal-neutral-3;

  &:not(.p-disabled):hover {
    border: 1px solid $color-neutral-blue-0;
  }

  &:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.1rem $color-neutral-blue-0;
  }

  &:focus-visible {
    outline: none;
  }

}

.p-highlight {
  // background: $color-green-2 !important;
  color: $color-neutral-blue-0 !important;
}

.p-link:focus {
  // box-shadow: 0 0 0 0.2rem $color-neutral-blue-0;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0px !important;
}


.p-datatable .p-datatable-tbody>tr>td {
  border-bottom: 1px solid $bg-colors;
}

.p-datatable .p-datatable-thead>tr>th {
  background: $new-a4-portal-neutral-3 !important;
}


.p-datatable .p-datatable-header {
  background: $color-white !important;
}


.p-datatable .p-datatable-thead>tr>th:last-child {
  text-align: left;
}

.p-datatable .p-datatable-tbody>tr>td:last-child {
  text-align: left;
}

.p-datatable .p-sortable-column:focus {
  // box-shadow: inset 0 0 0 0.1rem $color-neutral-blue-0;
}

.p-paginator {
  border-radius: 0px 0px 16px 16px !important;
}

.p-datatable-header {
  border-radius: 16px 16px 0px 0px !important;
}

.p-datatable input,
.p-datatable select,
.p-datatable mat-select,
.p-datatable p-dropdown {
  margin-bottom: 0px !important;
}

.p-dropdown {
  background: #f6f6f6;
  border: none !important;
}

.p-inputtext,
.p-dropdown {
  margin: 0px !important;
}

$small: 600px;

.p-calendar,
.p-inputtext,
.p-dropdown {
  @media screen and (max-width: $small) {
    width: 270px !important;
  }
}

.p-paginator-element {
  border: none !important;
}

.p-paginator {
  align-items: baseline !important;
}

.p-datatable .p-datatable-thead>tr>th {
  border-top: 1px solid $new-a4-portal-secondary !important;
  border-bottom: 1px solid $new-a4-portal-secondary !important;
}

.p-disabled {
  background: none !important;
}

.p-card .p-card-body {
  padding: 0px !important;
}

.p-datatable .p-datatable-header {
  padding: 20px !important;
  // padding-bottom: 20px !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 0.5rem 1rem !important;
}

sortalticon {
  display: none;
}

sortamountupalticon::before {
  content: "\2191";
}

sortamountdownicon::before {
  content: "\2193";
}

sortamountupalticon,
sortamountdownicon {
  svg {
    display: none;
  }
}

sortamountupalticon::before,
sortamountdownicon::before {
  font-size: 1rem;
  margin-left: 0;
  color: var(--blue3);
  position: relative;
  bottom: 4px;
  left: 4px;
}

.p-multiselect-close {
  border: none !important;
}

.p-checkbox-box {
  border: 1px solid $color-neutral-blue-8 !important;
}

p-card {
  padding-bottom: 1rem !important;
}

.ng-input {
  padding-left: 4px !important;
  top: 0px !important
}

.p-panel .p-panel-header {
  background: none !important;
  border-bottom: none !important;
}

.cancel-btn {
  background: $bg-colors;
  color: $color-neutral-blue-0;
  border: 1px solid $color-neutral-blue-0;
}

.p-tabview-nav-content {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #04662b !important;
}