.datepicker {

  .mat-form-field-flex {
    width: 150px;
    height: 48px;
    align-items: center;
  }

  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.datepicker-position-start {
  transform: translateX(10px) translateY(4px) !important;
}

.datepicker-position-end {
  transform: translateX(-155px) translateY(4px) !important;
}

.datepicker-icon {
  top: 14px;
}