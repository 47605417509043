@import 'src/styles/partials/variables';

mat-divider {
  &.neutral-2 {
    border-top-color: $new-a4-portal-neutral-0;
  }

  &.neutral-blue-6 {
    border-top-color: $color-neutral-blue-6;
  }
}

.mat-divider {
  border-top-color: $divider-neutral !important;
}
