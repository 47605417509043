@import "../../styles/partials/variables";

.mat-paginator-container {
  padding: 0px !important;
  justify-content: space-between !important;
}

.mat-paginator-navigation-previous, .mat-paginator-navigation-next {
  background: unset !important;
  color: $color-primary !important;

  &[disabled] {
    background: unset !important;
    color: $color-neutral-blue-6 !important;
  }
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: unset !important;
}

.audit-trial .mat-select-value {
  overflow:unset !important;
}

.audit-trial .mat-select {
  background-size: 15px !important;
}