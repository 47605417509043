mat-sidenav-container.no-backdrop {
  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: transparent !important;
  }
}

mat-sidenav {
  top: 0px !important;
  z-index: 2 !important;
  
  &.mat-drawer-end {
    max-width: unset !important;
  }

  .mat-drawer-inner-container {
    //overflow: initial;
    height: 100%;
    padding: 32px;
  }
}

.mat-drawer-inner-container {
  align-items: top !important;
}

.mat-drawer-container{
  background-color: #FFFFFF !important;
}

// .mat-sidenav-content {
//   width: 100% !important;
// }
mat-sidenav-content
{
  border-top-left-radius: 20px;
}
.mat-drawer-content {
  min-height: 100vh !important;
  overflow-x: hidden !important;
  z-index: 2 !important;
}

mat-sidenav .mat-drawer-inner-container {
  //overflow: initial;
  height: 100%;
  padding:20px !important;
  // padding-left: 30px !important;
}

.mat-sidenav-content {
  margin-left:230px !important;
}
