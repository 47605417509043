@import "./variables";

@mixin icon($size) {
  text-align: center;
  width: $size !important;
  height: $size !important;

  display: flex !important;
  place-content: center;
  align-items: center;

  svg {
    width: $size;
    height: $size;
  }
}

mat-icon {
  &[name='close'] {
    cursor: pointer;
  }
}

.icon-sm {
  @include icon(16px);
}

.icon-md {
  @include icon(21px);
}

.icon-md-1 {
  @include icon(24px);
  &.active {
    background-color: $new-a4-portal-neutral-0;
  }
}

.icon-lg {
  @include icon(28px);
}

.icon-xl {
  @include icon(50px);
}
