@import "./variables";

a {
  text-decoration: none;
  border-bottom: 2px solid $color-primary;
  padding-bottom: 4px;
  color: $color-primary;
}

.internal-link {
  color: $color-primary;
  border-bottom: 2px solid $color-primary;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;
  cursor: pointer;
}

.view-release-internal-link {
  color: $color-primary;
  border-bottom: 2px solid $color-primary;
  letter-spacing: 0.5px;
}

.disabled-link {
  opacity: 0.4;
}