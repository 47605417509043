@import "variables";

select {
  display: block;
  @extend .mat-body;
  color: $color-primary;
  border: 1px solid $color-transparent;
  box-sizing: border-box;
  resize: none;
  padding-left: 10px;
  padding-right: 30px;
  height: 44px;
  appearance: none;
  cursor: pointer;

  background: url('/assets/icons/fi_chevron-down.svg') no-repeat  96% 50% / 6% $new-a4-portal-neutral-3;

  &:hover {
    border: 1px solid $color-neutral-green-0;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: $color-neutral-blue-8;
    border-color: $color-neutral-blue-5;
    color: $color-neutral-blue-5;
  }

  // Does not have selected value
  &.ng-invalid.ng-touched,
  &.ng-invalid.ng-dirty {
    border: 1px solid $color-a4-portal-orange;
  }

  // Has selected value
  &.ng-valid {
    border: 1px solid $color-neutral-green-0;

  }

  option {
    color: $color-primary !important;
  }

  &.ng-touched.ng-invalid {
    border: 1px solid $color-a4-portal-orange;
  }
}

*:focus {
  outline: none;
}
