@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './partials/variables';


@font-face {
  font-family: Inter;
  font-weight: 700;
  src: local("Inter"), url(./././../assets/fonts/Inter/Inter-Bold.ttf) format("opentype");
}

@font-face {
  font-family: Inter;
  font-weight: 600;

  src: local("Inter"), url(./././../assets/fonts/Inter/Inter-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: local("Inter"), url(./././../assets/fonts/Inter/Inter-Medium.ttf) format("opentype");
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: local("Inter"), url(./././../assets/fonts/Inter/Inter-Light.ttf) format("opentype");
}

$head-font: "Inter";
$body-font: "Inter";

$typography-config: mat.define-typography-config(
  /* .mat-headline-1 */
  $headline-1: mat.define-typography-level(96px, 112px, 600, $head-font, $letter-spacing: 1px),
  /* .mat-headline-2 */
  $headline-2: mat.define-typography-level(38px, 38px, 700, $head-font, $letter-spacing: 1px),
  /* .mat-headline-3 */
  $headline-3: mat.define-typography-level(46px, 56px, 600, $head-font, $letter-spacing: 1px),
  /* .mat-headline-4 */
  $headline-4: mat.define-typography-level(34px, 36px, 700, $head-font, $letter-spacing: 1px),
  /*.mat-headline-5*/
  $headline-5: mat.define-typography-level(24px, 26px, 600, $head-font, $letter-spacing: 0.5px),
  /*.mat-headline-6 */
  $headline-6: mat.define-typography-level(20px, 24px, 600, $head-font, $letter-spacing: 0.5px),
  /*.mat-subtitle-2 */
  $subtitle-2: mat.define-typography-level(16px, 24px, 600, $head-font, $letter-spacing: 0.5px),
  /*.mat-subtitle-1 */
  $subtitle-1: mat.define-typography-level(24px, 32px, 500, $head-font, $letter-spacing: 0.25px),

  /* Base body text .mat-body, .mat-body-1 */
  $body-1: mat.define-typography-level(16px, 24px, 400, $body-font, $letter-spacing: 0px),
  /* Bolder body text .mat-body-strong, .mat-body-2 */
  $body-2: mat.define-typography-level(14px, 20px, 500, $body-font, $letter-spacing: 0.25px),

  /* .mat-small, .mat-caption */
  $caption: mat.define-typography-level(12px, 16px, 500, $body-font, $letter-spacing: 0.4px),
    /* .mat-button */
  $button: mat.define-typography-level(16px, 16px, 700, $body-font, $letter-spacing: 1.25px)
);