@import "../../styles/partials/variables";
@import 'src/styles/typography';

.mat-tooltip {
  background-color: $color-primary !important;
  color: $color-white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: unset !important;
  margin: 5px 5px 10px !important;
  max-width: 390px !important;
}
.tooltip-inner {
  font-family: $body-font !important;
  border-radius: 0% !important;
}
.mat-tooltip {
  overflow: unset !important;
}
.mat-tooltip.above:after {
  bottom: -0.5rem;
  right: calc(50% - 0.5rem);
  transform: rotate(0);
}
.mat-tooltip.right:after {
  top: calc(50% - 4px);
  right: calc(100% - 5px);
  transform: rotate(90deg);
}
.mat-tooltip::after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid $color-primary;
}
