@import "src/styles/partials/variables";

.vertical-menu {
  overflow-y: auto;
  position: sticky;
  top: 10px;
  
  .menu-item {
    padding: 8px;
    margin-bottom: 4px;
    cursor: pointer;
    color: $color-neutral-blue-3;
    font-weight: 600;
    border-radius: 4px;

    &.selected {
      background-color: $color-neutral-blue-9;
      color: $color-primary;
    }

    &.disabled {
      cursor: default;
      color: $color-neutral-blue-3;
    }
  }

  .menu-group {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 8px;
    margin-top: 16px;
  }
}
