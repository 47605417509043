@import "src/styles/partials/variables";

.mat-slide-toggle.mat-checked {
  & .mat-slide-toggle-bar {
    background-color: $color-neutral-blue-3 !important;
  }

  & .mat-slide-toggle-thumb {
    background-color: $color-primary !important;
  }
}

.mat-slide-toggle-bar {
  background-color: $color-neutral-blue-7 !important;
}

.mat-slide-toggle-thumb {
  background-color: $color-neutral-blue-2 !important;
}

.mat-slide-toggle-ripple.mat-ripple.mat-focus-indicator {
  display: none;
}

