@import "../../styles/partials/variables";

.context-menu-icon {
  background: unset;

  mat-icon {
    width: 24px !important;
    height: 24px !important;
    color: $color-primary !important;
  }

  &:disabled {
    mat-icon {
      color: $color-neutral-blue-6 !important;
    }
  }
}

.context-menu-dropdown {
  min-width: 200px !important;
  border-radius: 2px !important;

  mat-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: $color-primary !important;
    align-self: start;
  }

  button {
    padding: 0 10px !important;
    background: unset !important;
  }
}

.mat-menu-panel {
  max-width: 390px !important;
}

.hamburger-menu {
  position: absolute;
  width: 390px;
  //height: 330px;
  left: 144px;
  top: 18px !important;
  background: #FFFFFF;
  box-shadow: 0 2px 6px rgba(25, 57, 89, 0.22) !important;
}


.search-container {
  border-radius: 40px;
  align-items: center;
  width: 309px;
  left: calc(50% - 309px/2 + 428px);
  top: 16px;

  input {
    line-height: 30px;
    margin: 0 6px 0 16px;
    padding: 0;

    &::placeholder {
      color: #8C8C8C;
    }
  }
}
