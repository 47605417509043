/* Colors */

/* Primary */
$color-primary: #18191a;

/* Secondary */

/* Accent */
$color-accent: #ea5918;
$color-accent-1: #EF7839;
$color-accent-2: #F39561;
$color-accent-3: #F5AF87;
$color-accent-4: #F7BDA3;
$color-accent-5: #FDE5D9;

/* Neutral */
$color-neutral-blue: #1B59F8;
$color-neutral-blue-0: #0066DD;
$color-neutral-blue-1: #40678f;
$color-neutral-blue-2: #59799d;
$color-neutral-blue-3: #718caa;
$color-neutral-blue-4: #899fb8;
$color-neutral-blue-5: #a0b2c6;
$color-neutral-blue-6: #b8c7d6;
$color-neutral-blue-7: #d1d9e3;
$color-neutral-blue-8: #e7edf1;
$color-neutral-blue-9: #EAF2FF;
$color-neutral-blue-10: #CBEBFF;
$color-neutral-blue-disabled: #1b5af834;

$color-neutral-2: #6C7281;
$color-neutral-3: #7C7E8C;
$color-neutral-4: #8B939E;

$color-pale-1: #F7F9FB;

/* Orange */
$color-a4-portal-orange: #E85919;
$color-a4-portal-orange-20: #F9CAB0;

/* Cyan */
$color-a4-portal-cyan: #38AAE1;
$color-a4-portal-cyan-1: #D7EEF9;

/* Warn */
$color-warn-solid: #B53B20;

/* Green */
$color-neutral-green-0: #04662B;
$color-green: #75C067;
$color-green-1: #188038;
$color-green-2: #1880373c;

$color-yellow: #FCF7B6;

/* Grey */
$color-grey-1: #8C8C8C;
$color-grey-2: #9D9D9D;
$color-grey-3: #343D48;
$color-grey-4: #809FB4;

/* Dark blue */
$color-dark-blue: #0F2841;

$text-color: $color-primary;

/* Global margins */
$spacing-vertical: 24px;
$spacing-horizontal: 9vw;

$default-font: utile, Arial, sans-serif;

$color-approved: #E5FFE0;
$color-pending: #FFECE1;

//new-brand-stylings
$new-a4-portal-primary: #293276;
$new-a4-portal-secondary: #EAF2FF;
$color-white: #FFFFFF;
$color-disabled: #a9adc8;

//neutral-blues
$new-a4-portal-neutral: #B8C7D6;
$new-a4-portal-neutral-0: #C9D9F2;
$new-a4-portal-neutral-1: #718CAA;
$new-a4-portal-neutral-2: #3961A6;
$new-a4-portal-neutral-3: #F6F6F6;
$new-a4-portal-neutral-4: #F8F8F8;

$new-a4-portal-cyan: #C7C52A;
$new-a4-portal-green: #188038;

$new-a4-portal-accent-1: #B50000;
$new-a4-portal-accent-3: #F7E1DA;

$color-black: #000000;

$bg-colors: #F2F7F4;
$new-a4-portal-login: #00561F;

//yellow
$color-neutral-yellow-0: #EDB038;
$color-neutral-yellow-1: #FFCE00;
$divider-neutral: #D8DADA;
$color-transparent: #a0b2c600;

//fonts
$font-ash-color: #a1a5b7;
$neutral-ash: #f3f6f9 !important;

$ies-ash-primary-color: #f2f2f2;
$ies-ash-2-primary-color: #f8f8f8;