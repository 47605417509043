@import '../partials/variables';

mat-chip-list {
  mat-chip {
    border: 1px solid $color-primary;
    background: transparent;
    border-radius: 40px;
    padding: 4px 12px !important;
    min-height: 32px;
    height: auto;
    font-size: 16px !important;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;

    mat-icon {
      opacity: unset !important;
      cursor: pointer;
      width: 18px !important;
      height: 18px !important;
    }

    &:hover {
      background: $color-primary !important;
      color: $color-white !important;

      mat-icon {
        color: $color-white !important;
      }
    }

    .tag-content {
      width: fit-content;
    }
  }

  input {
    border: unset !important;
    background: unset !important;
    padding-right: 0px !important;
  }

}

.mat-standard-chip {
  min-height: 24px !important;
}
