@import 'src/styles/partials/_variables.scss';

.mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: 8px !important;
}

.mat-accordion .mat-expansion-panel .mat-expanded,
.mat-accordion .mat-expansion-panel .mat-expansion-panel-spacing {
  border-start-start-radius: 8px !important;
  border-start-end-radius: 8px !important;
  border-end-start-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  // box-shadow: 0 2px 6px $bg-colors !important;
  box-shadow: none !important;

}

.mat-accordion .mat-expansion-panel {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;

  &:hover {
    box-shadow: 0 0 8px $bg-colors !important;
  }

}

.mat-expansion-panel-header:not(.mat-expanded):hover {
  background: $color-white !important;
}

.mat-expansion-panel-header {
  padding: 0px !important;
}

.mat-expansion-panel {
  box-shadow: none !important;
  background: none !important;
}

.mat-accordion .mat-expansion-panel {
  box-shadow: none !important;
  background: none !important;

  &:hover {
    box-shadow: none !important;
    background: none !important;
  }

  &:active {
    box-shadow: none !important;
    background: none !important;
  }

  &:focus {
    box-shadow: none !important;
    background: none !important;
  }
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description
{
  top: 20px !important;
  position: relative;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  flex-basis: 0;
}

.remove-mat-expansion-panel-padding .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-body {
  padding: 0 24px 0px !important;
}

.mat-expansion-panel-header.mat-expanded {
  margin-bottom: 20px !important;
  height: 40px !important;
}
