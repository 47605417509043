/* You can add global styles to this file, and also import other style files */
@import 'styles/core';
@import 'styles/theme';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";

// @tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  overflow: auto;
  top: 0px !important;
  font-feature-settings: normal !important;
  --font-feature-settings: normal !important;
}

body {
  margin: 0;
  color: $text-color;
}

* {
  box-sizing: border-box;
}



html,
body {
  height: 100%;
  overflow: auto;
  top: 0px !important;
}

body {
  margin: 0;
  color: $text-color;
}

* {
  box-sizing: border-box;
}


.mat-body, textarea, p-calendar, p-dropdown, .p-link, .p-component, select, mat-select, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
   margin-bottom: 13px;
}

input {
  padding: 10px !important
}

select {
  padding: 10px !important
}


.w-12-rem {
  width: 12rem !important;
}

.max-width-lg > * {
  max-width: 1440px !important;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  align-self: center;
}





// Background image with radial gradient
@mixin bg-img-gradient($url) {
  background-image: url($url), radial-gradient(41.92% 41.92% at 50% 50%, rgba(0, 62, 117, 0.66) 0%, rgba(57, 105, 148, 0.42) 100%);
}
.img {
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}

@mixin bg-img($url) {
  @extend .img;
  background: url($url);
}

@mixin bg-img-gradient($url) {
  @extend .img;
  background-image: url($url), linear-gradient(90deg, $color-pale-1 5.18%, $color-neutral-blue-8 94.26%);
}

@mixin oval-background($background, $icon-width: 30px, $icon-height: 30px, $size: 80px) {
  background: $background;
  border-radius: 50%;
  opacity: 0.96;
  height: $size;
  width: $size;

  min-height: $size;
  min-width: $size;

  place-content: center;
  align-items: center;
  display: flex;

  mat-icon {
    color:$color-white;
    width: $icon-width;
    height: $icon-height;
  }
}

@mixin absolute-center() {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

@mixin overflow-ellipsis($max-width) {
  width: $max-width;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

@mixin max-width($max-width) {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    max-width: $max-width;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: center;
  }
}

@mixin col-width($width) {
  flex: 0 1 $width;
  max-width: $width;
  min-width: $width;
  word-wrap: break-word;
}

@function disabled-color($color) {
  @return rgba($color, 38%);
}

.max-width-lg {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
}
.flex-end_align
{
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-end;
  align-items: flex-end;
}
.toolbar
{
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
}

.flex-end_al
{
  flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;
    flex: 1 1 100%;
    max-width: 100%;
}
.matalign
{
  background: red($color: #814747);
}

.mr30
{
  margin-right: 30px;
}
.fl
{
  flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    max-width: 100%;
}
.fl2
{
  flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 60%;
    max-width: 100%;
}
.flex-row
{ 
  flex-flow: wrap;
    box-sizing: border-box;
    display: flex;
}
.flexend
{
  flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch flex-end;
    align-items: stretch;
}
@mixin max-width($max-width) {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    max-width: $max-width;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: center;
  }
}
.fullwidth {
  max-width: 1440px;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  align-self: center;
}
.fullwidth-head
{
  margin-left: 10px;
}
.backspacetop{
  margin-top: 20px;
}

  .backspacetop2 {
    margin-top: 20px;
    position: absolute;
}
