@import "../../styles/partials/variables";
@import 'src/styles/typography';

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: none !important
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: $color-neutral-blue !important;
}

.mat-mdc-tab-label-content {
  font-family: $body-font !important;
  font-weight: 400 !important;
}

.mat-mdc-tab-body {
  padding-top: 16px !important;
}

// .mat-mdc-tab-header {
//   left: 20px !important;
// }
.mdc-tab {
  flex: none !important;
}

.mat-mdc-tab-body-content {
  overflow: unset !important; 
}
