@import "variables";

input,
textarea {
  @extend .mat-body;
  border-radius: 4px;
  color: $color-primary;
  border: 1px solid $color-transparent;
  box-sizing: border-box;
  resize: none;
  padding-left: 10px;
  height: 44px;
  width: 400px;
  font-weight: 400;
  background: $color-white;
  margin-bottom: 14px;
  padding: 10px;
  line-height: 16px !important;


  &:hover {
    border: 1px solid $color-neutral-blue;
  }

  &:focus {
    border: 2px solid $color-neutral-blue;
  }

  &::placeholder {
    color: $color-grey-4;
  }

  &.ng-invalid.ng-touched,
  &.ng-invalid.ng-dirty {
    border: 1px solid $color-a4-portal-orange;
  }

}

$small: 600px;

input,
textarea {
  @media screen and (max-width: $small) {
    width: 270px !important;
  }
}

input[type=file]:hover { 
border: none !important;
}
input[type=file]:active { 
  border: none !important;
  }

input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  // border: 1px solid #49505785;
  height: 70px;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}