@import "./../partials/variables";

.tbl-border {
  border: 1px solid $color-neutral-blue-8;
}

.tbl {
  .tbl-header-row, .tbl-body-row {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.primary {
      background-color: $new-a4-portal-neutral-0;
    }

    &.secondary {
      background-color: $color-neutral-blue-8;
    }
  }

  .tbl-body-row {
    &.expanded {
      background-color: $new-a4-portal-secondary;
    }

    &.selected {
      background-color: $new-a4-portal-neutral-0;
    }
  }

  .tbl-header-row {
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
  }

  .tbl-high-row {
    min-height: 54px;
    box-shadow: 0px 2px 6px rgba(25, 57, 89, 0.22);
  }

  .tbl-cell {
    display: flex;
    flex: 1;
  }
}
